<template>
  <div class="flex p-2">
    <div v-if="!editMode" class="w-full flex">
      <div class="flex flex-col w-10/12">
        <div class="flex mb-1">
          <span class="mr-2">Pangakonto:</span>
          <span>{{ bank.iban_code }}</span>
        </div>
        <div class="flex mb-1">
          <span class="mr-2">BIC/SWIFT kood:</span>
          <span>{{ bank.swift_code }}</span>
        </div>
        <div class="flex mb-1">
          <span class="mr-2">Pank:</span>
          <span>{{ bank.bank }}</span>
        </div>
      </div>
      <div class="flex w-2/12 items-start justify-end">
        <div class="flex">
          <button class="alt-button-pending mr-2" @click="editMode = true">
            <span class="typcn typcn-edit icon"></span>
          </button>
          <button
            class="alt-button-danger ml-2"
            @click="deleteBankCardConfirmation = true"
          >
            <span class="typcn typcn-times icon"></span>
          </button>
        </div>
      </div>
    </div>
    <div v-if="editMode" class="w-full flex flex-col">
      <form-label
        :title="'Pangakonto'"
        :required="true"
        :valid="!$v.editables.iban_code.$invalid"
        :error="$v.editables.iban_code.$error"
        :error-text="
          editables.iban_code.length === 0
            ? 'Kohustuslik'
            : !$v.editables.iban_code.ibanValidator
            ? 'IBAN kood pole korrektne'
            : 'asd'
        "
      ></form-label>
      <input
        type="text"
        v-model="editables.iban_code"
        :class="
          editables.iban_code !== null &&
          editables.iban_code.length > 0 &&
          !$v.editables.iban_code.ibanValidator
            ? 'shadow-dangeroutline'
            : ''
        "
        class="bg-offwhite flex-grow mb-2 mobile:mr-0 shadow focus:shadow-outline mobile:mb-4 w-full"
        @input="debounceSaveChanges"
      />
      <form-label :title="'BIC/SWIFT kood'" :required="false"></form-label>
      <input
        type="text"
        v-model="editables.swift_code"
        class="bg-offwhite flex-grow mb-2 mobile:mr-0 shadow focus:shadow-outline mobile:mb-4 w-full"
      />
      <form-label :title="'Pank'" :required="false"></form-label>
      <input
        type="text"
        v-model="editables.bank"
        class="bg-offwhite flex-grow mb-2 mobile:mr-0 shadow focus:shadow-outline mobile:mb-4 w-full"
      />
      <div class="flex">
        <button
          class="new-button-green mr-2"
          @click="changeBankAccountConfirmation = true"
        >
          <span class="typcn typcn-plus icon"></span>
          <span class="label">Salvesta</span>
        </button>
        <button class="new-button-danger ml-2" @click="editMode = false">
          <span class="typcn typcn-times icon"></span>
          <span class="label">Tühista</span>
        </button>
      </div>
    </div>
    <confirm-modal
      v-if="deleteBankCardConfirmation"
      :text="'Kas olete kindel, et soovite pangakonto kustutada?'"
      @closeModal="deleteBankCardConfirmation = false"
      @confirm="deleteBankCard"
    ></confirm-modal>
    <confirm-modal
      v-if="changeBankAccountConfirmation"
      :text="'Kas olete kindel, et soovite pangakonto andmeid muuta?'"
      @closeModal="changeBankAccountConfirmation = false"
      @confirm="changeBankAccount"
    ></confirm-modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { debounce } from "lodash";
import BicFromIBAN from "@/assets/mixins/BicFromIBAN";
import ConfirmModal from "@/components/reusable/ConfirmModal";

const ibanValidator = val => {
  if (val.length === 20) {
    let code = val.substring(4).concat(val.substring(0, 4));
    code = code.replaceAll(/E/g, "14");
    let n1 = parseInt(code.substring(0, 9)) % 97;
    let n2 = parseInt(n1.toString(10).concat(code.substring(9, 16))) % 97;
    let n3 = parseInt(n2.toString(10).concat(code.substring(16))) % 97;

    return n3 === 1;
  } else {
    return false;
  }
};
export default {
  name: "BankCard",
  components: { ConfirmModal },
  props: {
    bank: Object
  },
  mixins: [BicFromIBAN],
  data() {
    return {
      editMode: false,
      changeBankAccountConfirmation: false,
      deleteBankCardConfirmation: false,
      editables: {
        iban_code: "",
        bank: "",
        swift_code: ""
      }
    };
  },
  mounted() {
    this.editables.bank = this.bank.bank;
    this.editables.iban_code = this.bank.iban_code;
    this.editables.swift_code = this.bank.swift_code;
  },
  methods: {
    debounceSaveChanges: debounce(function() {
      this.getBankInfo();
    }, 1000),
    getBankInfo() {
      let bankInfo = this.parseIban(this.editables.iban_code);
      if (bankInfo) {
        this.editables.swift_code = bankInfo.swift_code;
        this.editables.bank = bankInfo.bank;
      }
    },
    deleteBankCard() {
      this.apiRequest(
        "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/banks/" +
          this.bank.id +
          "/delete/",
        "delete",
        true
      ).then(res => {
        if (res.status === 200) {
          this.$emit("bankAccountChanged");
        }
      });
    },
    changeBankAccount() {
      this.apiRequest(
        "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/banks/" +
          this.bank.id +
          "/edit/",
        "patch",
        true,
        this.editables
      ).then(res => {
        if (res.status === 200) {
          this.editMode = false;
          this.$emit("bankAccountChanged");
        }
      });
    }
  },
  validations: {
    editables: {
      iban_code: {
        required,
        ibanValidator
      }
    }
  }
};
</script>

<style scoped></style>
