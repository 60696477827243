var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col p-5"},[_c('form-label',{attrs:{"title":'Pangakonto',"required":true,"valid":!_vm.$v.bank.iban_code.$invalid,"error":_vm.$v.bank.iban_code.$error,"error-text":_vm.bank.iban_code.length === 0
        ? 'Kohustuslik'
        : !_vm.$v.bank.iban_code.ibanValidator
        ? 'IBAN kood pole korrektne'
        : 'asd'}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.bank.iban_code),expression:"bank.iban_code"}],staticClass:"bg-offwhite flex-grow mb-2 mobile:mr-0 shadow focus:shadow-outline mobile:mb-4 w-full",class:_vm.bank.iban_code !== null &&
      _vm.bank.iban_code.length > 0 &&
      !_vm.$v.bank.iban_code.ibanValidator
        ? 'shadow-dangeroutline'
        : '',attrs:{"type":"text"},domProps:{"value":(_vm.bank.iban_code)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.bank, "iban_code", $event.target.value)},_vm.debounceSaveChanges]}}),_c('form-label',{attrs:{"title":'BIC/SWIFT kood',"required":false}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.bank.swift_code),expression:"bank.swift_code"}],staticClass:"bg-offwhite flex-grow mb-2 mobile:mr-0 shadow focus:shadow-outline mobile:mb-4 w-full",attrs:{"type":"text"},domProps:{"value":(_vm.bank.swift_code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.bank, "swift_code", $event.target.value)}}}),_c('form-label',{attrs:{"title":'Pank',"required":false}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.bank.bank),expression:"bank.bank"}],staticClass:"bg-offwhite flex-grow mb-2 mobile:mr-0 shadow focus:shadow-outline mobile:mb-4 w-full",attrs:{"type":"text"},domProps:{"value":(_vm.bank.bank)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.bank, "bank", $event.target.value)}}}),_c('div',{staticClass:"flex"},[_c('button',{staticClass:"alt-button-green",on:{"click":_vm.createBankAccount}},[_c('span',{staticClass:"typcn typcn-plus icon"}),_c('span',{staticClass:"label"},[_vm._v("Salvesta")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }