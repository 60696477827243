<template>
  <div class="flex flex-col">
    <div class="flex flex-row justify-between mobile:flex-col mb-4">
      <h2>Ettevõtte seaded</h2>
      <button
        class="new-button-pending"
        @click="editMode = true"
        v-if="!editMode && canEdit"
      >
        <span class="typcn-edit typcn icon"></span>
        <span class="label">Muuda</span>
      </button>
    </div>
    <div class="error-box flex-col w-8/12 mobile:w-full mb-4" v-if="$v.$error">
      <div class="flex flex-col w-full" v-if="$v.$error">
        <span
          v-if="
            $v.editables.company_email.$error && !editables.company_email.email
          "
        >
          Meiliaadress aadress on ebakorrektne
        </span>
        <span
          v-if="
            editables.iban_code !== null &&
              editables.iban_code.length > 0 &&
              !$v.editables.iban_code.ibanValidator
          "
          >Arvelduskonto number (IBAN) ei ole valiidne</span
        >
        <span
          v-if="
            editables.iban_code !== null &&
              editables.iban_code.length > 0 &&
              $v.editables.iban_account_name.$error &&
              !$v.editables.iban_account_name.required
          "
        >
          Palun sisestage arvelduskontoga seotud nimi
        </span>
      </div>
    </div>
    <div class="flex flex-row mobile:flex-col mobile:mb-4">
      <div
        v-if="companyLoaded"
        class="flex flex-col w-8/12 mobile:w-full mobile:mr-0 mr-3 mobile:mb-4"
      >
        <div class="flex flex-col mb-4" v-if="!editMode">
          <div class="flex flex-col mb-2">
            <h4>Ettevõtte meil</h4>
            <span>{{ companyData.company_email }}</span>
          </div>
        </div>
        <div class="flex flex-col mb-4" v-if="editMode">
          <h4>Ettevõtte meil</h4>
          <div class="flex flex-row items-center">
            <input type="text" class="add-email-input" v-model="emailField" />
            <button
              v-if="emailLoaded && emailVerified"
              @click="deleteEmail"
              class="new-button-danger ml-2 h-full"
            >
              <span class="label">Kustuta</span>
            </button>
          </div>
          <div class="flex py-4 items-center" v-if="emailLoaded && canEdit">
            <div v-if="emailVerified === 1" class="flex items-center mr-4">
              <span class="mr-2">Meiliaadress on kinnitatud.</span>
              <div
                class="flex cursor-default rounded-full bg-offwhite items-center"
              >
                <span
                  class="typcn text-white bg-green transform typcn-tick duration-150 rounded-full p-2 leading-none"
                ></span>
              </div>
            </div>
            <div class="flex h-full" v-if="emailVerified === 1">
              <button
                class="new-button-danger new-button-small h-full"
                @click="deleteEmail"
              >
                <span class="icon typcn typcn-times"></span>
                <span class="label">Tühista kinnitus</span>
              </button>
            </div>
            <div v-if="emailVerified === 2" class="flex">
              <span
                class="mr-2 break-words font-medium bg-offwhite rounded-xl p-2 flex items-center"
              >
                <span
                  class="typcn typcn-tick bg-green rounded-full p-2 text-white leading-none mr-4"
                ></span
                ><span class="pr-4"
                  >Teile on e-kiri saadetud, palun kontrollige oma postkasti
                  ning kinnitage enda meiliaadress.</span
                ></span
              >
            </div>
            <div
              v-if="emailVerified === 3"
              class="items-center flex flex-row mr-4"
            >
              <span class="mr-2">Meiliaadress on kinnitamata</span>
              <div
                class="flex cursor-default rounded-full bg-offwhite items-center"
              >
                <span
                  class="typcn typcn-times text-white bg-danger p-2 rounded-full leading-none"
                ></span>
              </div>
            </div>
            <div class="flex h-full" v-if="emailVerified === 3">
              <button
                class="new-button-green h-full"
                @click="verifyEmaiConfirmationActive = true"
              >
                <span class="icon typcn typcn-tick"></span>
                <span class="label">Luba kirjad oma meiliaadressilt</span>
              </button>
            </div>
          </div>
        </div>
        <div class="flex flex-col mb-4">
          <h4>Ettevõtte telefoninumber</h4>
          <span v-if="!editMode"> {{ companyData.telephone_number }}</span>
          <span v-if="!editMode && !companyData.telephone_number"> - </span>
          <div class="w-full flex mb-2" v-if="editMode">
            <select
              v-model="countryCode"
              class="mobile:w-3/12 medium:w-3/12 w-2/12 mr-2"
            >
              <option value="+372">+372</option>
            </select>
            <input
              class="mobile:w-9/12 medium:w-9/12 w-10/12 add-project-input"
              placeholder="Telefoninumber"
              v-model="phoneNumberInput"
              type="tel"
              pattern="[0-9]*"
            />
          </div>
        </div>
        <div class="flex flex-col mb-6">
          <h4>Ettevõtte aadress</h4>
          <span v-if="!editMode && companyData.address" class="pl-2 w-full">
            {{ companyData.address.long_address }}</span
          >
          <div class="flex flex-col w-full" v-if="editMode">
            <location-inaddress
              v-if="!editables.address"
              :text="'Sisestage ettevõtte aadress'"
              :location="editables.address"
              @locationChanged="handleCompanyLocationChange"
              class="mb-2"
            >
            </location-inaddress>
            <div
              v-if="editMode && editables.address"
              class="mb-2 bg-offwhite w-full rounded-xl py-1 px-1 flex items-center shadow"
            >
              <span class="pl-2">{{
                editables.address.ipikkaadress
                  ? editables.address.ipikkaadress
                  : editables.address.long_address
              }}</span>
              <button
                class="new-button-danger ml-auto"
                @click="editables.address = null"
              >
                <span class="icon"
                  ><img src="/bc21/trash.svg" class="h-5 filter-to-white" />
                </span>
                <span class="label">Tühjenda</span>
              </button>
            </div>
          </div>
        </div>
        <div class="flex flex-col mb-4" v-if="!editMode">
          <h4>Ettevõtte registrikood</h4>
          <span>{{ companyData.register_code }}</span>
        </div>
        <div class="flex flex-col mb-4" v-if="editMode">
          <h4>Ettevõtte registrikood</h4>
          <input
            type="text"
            class="add-email-input"
            v-model="editables.register_code"
          />
        </div>
        <div
          class="flex flex-col mb-6"
          v-if="!editMode && companyData.tax_code"
        >
          <h4>Ettevõtte KMKR kood</h4>
          <span>{{ companyData.tax_code }}</span>
          <span v-if="companyData.tax_code === ''">
            -
          </span>
        </div>
        <div class="flex flex-col mb-6" v-if="editMode">
          <h4>Ettevõtte KMKR kood</h4>
          <input
            type="text"
            class="add-email-input"
            placeholder="KMKR kood (valikuline)"
            v-model="editables.tax_code"
          />
        </div>
        <div class="flex flex-col mb-4">
          <h4 class="mb-2">Ettevõtte pangakontod</h4>
          <div class="flex flex-col">
            <div v-if="companyData.banks.length === 0">
              <span>-</span>
            </div>
            <div class="flex flex-wrap">
              <bank-card
                v-for="bank in companyData.banks"
                :key="bank.id"
                :bank="bank"
                class="mb-2 w-1/2"
                @bankAccountChanged="newBankSaved"
              >
              </bank-card>
            </div>
            <div class="flex flex-row justify-between mb-2">
              <button class="alt-button-green" @click="newBankAccount = true">
                <span class="typcn typcn-plus icon"></span>
                <span class="label">Lisa konto</span>
              </button>
              <button
                v-if="newBankAccount"
                class="new-button-danger"
                @click="newBankAccount = false"
              >
                <span class="label">Tühista</span>
                <span class="typcn typcn-times icon"></span>
              </button>
            </div>
            <new-bank-account
              v-if="newBankAccount"
              @newBankSaved="newBankSaved"
            ></new-bank-account>
          </div>
        </div>
        <div class="flex flex-col mb-4">
          <h4>Töötajad peavad asukohta sisetama tööaja mõõtmises</h4>
          <base-checkbox
            :base-val="editables.require_task_location"
            @valueChanged="
              e => {
                editables.require_task_location = e;
              }
            "
          ></base-checkbox>
        </div>
        <div v-if="editMode" class="mobile:w-full mobile:justify-center flex">
          <button @click="saveChanges" class="new-button-green">
            <span class="icon typcn typcn-tick"></span>
            <span class="label">Salvesta muudatused</span>
          </button>
          <saving-loader :saving="saving" :saved="saved"></saving-loader>
        </div>
      </div>

      <div class="flex w-4/12 ml-3 mobile:ml-0 mobile:w-full">
        <div class="flex flex-col w-full">
          <div class="flex flex-col mb-4 items-center" v-if="logoLoaded">
            <h4 class="mb-3">Ettevõtte logo</h4>
            <div
              class="flex justify-center items-center max-h-64 max-w-64 mb-4 border-green border rounded-xl"
              :class="companyData.image ? '' : 'h-64 w-64'"
            >
              <img
                v-if="companyData.image"
                :src="logoUrl"
                class="max-h-64 max-w-64 rounded-xl"
              />
              <span v-if="!companyData.image" class="">Logo puudub</span>
            </div>
            <div class="flex justify-center" v-if="!addLogo">
              <button class="new-button-green" @click="addLogo = true">
                <span class="label">Lisa logo</span>
              </button>
            </div>
            <div class="flex flex-col w-full" v-if="canEdit && editMode">
              <div class="single-file-upload mb-4" v-if="addLogo">
                <div class="input-box">
                  {{ imagePreview ? imagePreview.filename : "Valige fail" }}
                </div>
                <label for="upload-image" class="button">Vali</label>
                <input
                  id="upload-image"
                  type="file"
                  accept=".jpeg,.jpg,.png,.gif"
                  ref="logo"
                  class="absolute opacity-0 w-0 p-0"
                  data-buttonText
                  @input="loadPreview()"
                />
              </div>
              <div class="flex flex-col items-center mb-4" v-if="imagePreview">
                <h4>Eelvaade</h4>
                <img
                  :src="imagePreview.url"
                  v-if="imagePreview"
                  class="h-64 w-64 mb-4 border-green border rounded-xl"
                />
                <button @click="uploadImage" class="new-button-green">
                  <span class="icon typcn typcn-tick"></span>
                  <span class="label">Lae üles</span>
                </button>
              </div>
              <button
                @click="clearInput"
                class="new-button-danger self-center"
                v-if="addLogo"
              >
                <span class="icon typcn typcn-times"></span>
                <span class="label">Tühista</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <confirm-modal
      v-if="verifyEmaiConfirmationActive"
      :text="
        'Meiliaadressi kinnitades nõustute, et Gewodo veebilehelt e-kirjade saatmisel lubate enda meiliaadressi kasutada. Peale kinnitamist tuleb Teie ettevõtte postkasti kiri, pärast mille kinnitamist tulevad arved ning pakkumised Teie meiliaadressiga kliendile.'
      "
      @confirm="verifyEmail"
      @closeModal="verifyEmaiConfirmationActive = false"
    >
    </confirm-modal>
  </div>
</template>

<script>
// import Compressor from "compressorjs";
import RequestHandler from "@/assets/mixins/RequestHandler";
import { email, required } from "vuelidate/lib/validators";
import LocationInaddress from "@/components/reusable/LocationInaddress";
import SavingLoader from "@/components/reusable/SavingLoader";
import isEqual from "lodash/isEqual";

import Compressor from "compressorjs";
import ConfirmModal from "@/components/reusable/ConfirmModal";
import BicFromIBAN from "@/assets/mixins/BicFromIBAN";
import BankCard from "@/components/company/settings/BankCard";
import NewBankAccount from "@/components/company/settings/NewBankAccount";

const ibanValidator = val => {
  if (val.length === 20) {
    let code = val.substring(4).concat(val.substring(0, 4));
    code = code.replaceAll(/E/g, "14");
    let n1 = parseInt(code.substring(0, 9)) % 97;
    let n2 = parseInt(n1.toString(10).concat(code.substring(9, 16))) % 97;
    let n3 = parseInt(n2.toString(10).concat(code.substring(16))) % 97;

    return n3 === 1;
  } else {
    return false;
  }
};

export default {
  components: {
    NewBankAccount,
    BankCard,
    ConfirmModal,
    LocationInaddress,
    SavingLoader
  },
  data() {
    return {
      success: false,
      saving: false,
      saved: false,
      addLogo: false,
      imagePreview: null,
      compressedImage: null,
      logoUrl: "",
      logoLoaded: false,
      companyData: null,
      companyLoaded: false,
      emailField: "",
      editables: {
        address: null,
        iban_code: "",
        iban_account_name: "",
        register_code: "",
        tax_code: "",
        company_email: "",
        telephone_number: "",
        require_task_location: false
      },
      editMode: false,
      countryCode: "+372",
      phoneNumberInput: "",
      emailVerified: 4,
      emailVerifiedText: "",
      emailIconHovered: false,
      emailLoaded: false,
      verifyEmaiConfirmationActive: false,
      newBankAccount: false
    };
  },
  mixins: [RequestHandler, BicFromIBAN],
  watch: {
    phoneNumberInput() {
      const test = new RegExp(/[a-zA-Z\s]/);
      if (this.phoneNumberInput !== null) {
        this.editables.telephone_number =
          this.countryCode + this.phoneNumberInput.replace(test, "");
      }
    },
    compressedImage() {
      this.$store.state.loading = true;
      const data = new FormData();
      data.append(
        "image",
        new File(
          [this.compressedImage],
          this.$store.state.companyData.activeCompany.uuid + ".jpg",
          {
            type: "image/jpeg"
          }
        )
      );
      this.apiRequest(
        "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/logo/add/",
        "post",
        true,
        data
      ).then(
        res => {
          this.$store.state.loading = false;
          if (res) {
            this.success = true;
            this.$refs.logo.files.value = null;
            this.clearInput();
            this.$store.dispatch("companyData/retrieveCompanyData");
            this.loadCompanyLogo(res.data.logo_id);
          }
        },
        err => {
          this.$store.state.loading = false;
          console.error(err);
        }
      );
    },
    emailField() {
      this.editables.company_email = this.emailLowercased;
    }
  },
  methods: {
    newBankSaved() {
      this.newBankAccount = false;
      this.loadCompanyInformation();
    },
    checkCompanyEmailVerified() {
      this.emailLoaded = false;

      this.apiRequest(
        "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/check-email-verified/",
        "get",
        true
      ).then(res => {
        if (res.status === 200) {
          this.emailVerified = 1;
          this.emailVerifiedText = "Teie meiliaadress on kinnitatud";
        } else if (res.status === 204) {
          this.emailVerified = 3;
          this.emailVerifiedText = "Teie meiliaadress ei ole veel kinnitatud";
        } else if (res.status === 208) {
          this.emailVerified = 2;
          this.emailVerifiedText = "Teile on saadetud kinnitud meil.";
        }
        this.emailLoaded = true;
      });
    },
    verifyEmail() {
      this.apiRequest(
        "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/verify-email/",
        "post",
        true,
        { email: this.companyData.company_email }
      ).then(res => {
        if (res) {
          this.checkCompanyEmailVerified();
        }
      });
    },
    deleteEmail() {
      this.apiRequest(
        "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/email/delete/",
        "delete",
        true
      ).then(res => {
        if (res) {
          this.checkCompanyEmailVerified();
        }
      });
    },
    saveChanges() {
      this.$v.$touch();
      this.saved = false;
      if (!this.$v.$error) {
        this.saving = true;
        let data = {};

        data.company_email = this.editables.company_email;
        data.telephone_number = this.editables.telephone_number;
        data.register_code = this.editables.register_code;
        data.tax_code = this.editables.tax_code;
        data.iban_code = this.editables.iban_code;
        data.iban_account_name = this.editables.iban_account_name;
        data.require_task_location = this.editables.require_task_location;
        if (!isEqual(this.companyData.address, this.editables.address)) {
          data.address = this.editables.address;
        }
        this.apiRequest(
          "company/" +
            this.$store.state.companyData.activeCompany.uuid +
            "/edit/",
          "patch",
          true,
          data
        ).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.saving = false;
            this.saved = true;
            let self = this;
            setTimeout(function() {
              self.saved = false;
            }, 5000);
            this.loadCompanyInformation();
            this.$store.dispatch("companyData/retrieveCompanyData");
          }
        });
      }
    },
    handleCompanyLocationChange(e) {
      this.editables.address = e;
    },
    initEditables(data) {
      this.editables.iban_code = data.iban_code;
      this.editables.iban_account_name = data.iban_account_name;
      this.editables.register_code = data.register_code;
      this.editables.tax_code = data.tax_code;
      this.editables.company_email = data.company_email;
      this.editables.address = data.address;
      this.emailField = data.company_email;
      if (data.telephone_number && data.telephone_number.length > 0) {
        this.phoneNumberInput = data.telephone_number.substring(4);
      }
      this.editables.require_task_location = data.require_task_location;
    },
    loadCompanyInformation() {
      this.apiRequest(
        "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/details/",
        "get",
        true
      ).then(res => {
        this.initEditables(res.data);
        this.companyData = res.data;
        if (this.companyData.telephone_number) {
          this.phoneNumberInput = this.companyData.telephone_number.slice(4);
        }
        this.loadCompanyLogo(null);
        this.companyLoaded = true;
      });
    },
    uploadImage() {
      let self = this;

      function compressImage() {
        new Compressor(self.$refs.logo.files[0], {
          quality: 0.7,
          success(result) {
            self.compressedImage = result;
          }
        });
      }

      compressImage();
    },
    loadPreview() {
      const file = this.$refs.logo.files[0];
      this.imagePreview = {};
      this.imagePreview.filename = this.$refs.logo.files[0].name;
      this.imagePreview.url = URL.createObjectURL(file);
    },
    clearInput() {
      this.imagePreview = null;
      this.$refs.logo.value = "";
      this.addLogo = false;
    },
    loadCompanyLogo(logo) {
      if (logo !== null) {
        this.apiRequest(
          "files/public/" + logo + "/?type=CompanyLogo",
          "get"
        ).then(res => {
          this.logoUrl = res.data.file_url;
          this.logoLoaded = true;
        });
      } else if (this.companyData.image) {
        this.apiRequest(
          "files/public/" + this.companyData.image + "/?type=CompanyLogo",
          "get"
        ).then(res => {
          this.logoUrl = res.data.file_url;
          this.logoLoaded = true;
        });
      }
      this.logoLoaded = true;
    }
  },

  mounted() {
    this.loadCompanyInformation();
    if (this.canEdit) {
      this.checkCompanyEmailVerified();
      this.editMode = true;
    } else {
      this.editMode = false;
      this.emailLoaded = true;
    }
  },
  computed: {
    sanitizedIban() {
      if (this.editables.iban_code === null) {
        return "";
      }
      return this.editables.iban_code.replaceAll(" ", "");
    },
    emailLowercased() {
      return this.emailField.toLowerCase();
    },
    canEdit() {
      return ["R0", "R1", "R2"].includes(
        this.$store.state.companyData.activeCompany.worker_permission
      );
    }
  },
  validations() {
    if (
      this.editables.iban_code !== null &&
      this.editables.iban_code.length > 0
    ) {
      return {
        editables: {
          iban_code: {
            required,
            ibanValidator
          },
          iban_account_name: {
            required
          },
          company_email: {
            email
          },
          register_code: {
            required
          }
        }
      };
    } else {
      return {
        editables: {
          company_email: {
            email
          },
          register_code: {
            required
          }
        }
      };
    }
  }
};
</script>
