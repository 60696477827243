<template>
  <div class="flex flex-col p-5">
    <form-label
      :title="'Pangakonto'"
      :required="true"
      :valid="!$v.bank.iban_code.$invalid"
      :error="$v.bank.iban_code.$error"
      :error-text="
        bank.iban_code.length === 0
          ? 'Kohustuslik'
          : !$v.bank.iban_code.ibanValidator
          ? 'IBAN kood pole korrektne'
          : 'asd'
      "
    ></form-label>
    <input
      type="text"
      v-model="bank.iban_code"
      :class="
        bank.iban_code !== null &&
        bank.iban_code.length > 0 &&
        !$v.bank.iban_code.ibanValidator
          ? 'shadow-dangeroutline'
          : ''
      "
      class="bg-offwhite flex-grow mb-2 mobile:mr-0 shadow focus:shadow-outline mobile:mb-4 w-full"
      @input="debounceSaveChanges"
    />
    <form-label :title="'BIC/SWIFT kood'" :required="false"></form-label>
    <input
      type="text"
      v-model="bank.swift_code"
      class="bg-offwhite flex-grow mb-2 mobile:mr-0 shadow focus:shadow-outline mobile:mb-4 w-full"
    />
    <form-label :title="'Pank'" :required="false"></form-label>
    <input
      type="text"
      v-model="bank.bank"
      class="bg-offwhite flex-grow mb-2 mobile:mr-0 shadow focus:shadow-outline mobile:mb-4 w-full"
    />
    <div class="flex">
      <button class="alt-button-green" @click="createBankAccount">
        <span class="typcn typcn-plus icon"></span>
        <span class="label">Salvesta</span>
      </button>
    </div>
  </div>
</template>

<script>
import BicFromIBAN from "@/assets/mixins/BicFromIBAN";
import { required } from "vuelidate/lib/validators";
import { debounce } from "lodash";

const ibanValidator = val => {
  if (val.length === 20) {
    let code = val.substring(4).concat(val.substring(0, 4));
    code = code.replaceAll(/E/g, "14");
    let n1 = parseInt(code.substring(0, 9)) % 97;
    let n2 = parseInt(n1.toString(10).concat(code.substring(9, 16))) % 97;
    let n3 = parseInt(n2.toString(10).concat(code.substring(16))) % 97;

    return n3 === 1;
  } else {
    return false;
  }
};

export default {
  name: "NewBankAccount",
  mixins: [BicFromIBAN],
  data() {
    return {
      bank: {
        iban_code: "",
        swift_code: "",
        bank: ""
      }
    };
  },
  methods: {
    debounceSaveChanges: debounce(function() {
      this.getBankInfo();
    }, 1000),
    getBankInfo() {
      let bankInfo = this.parseIban(this.bank.iban_code);
      if (bankInfo) {
        this.bank.swift_code = bankInfo.swift_code;
        this.bank.bank = bankInfo.bank;
      }
    },
    createBankAccount() {
      this.apiRequest(
        "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/banks/create/",
        "post",
        true,
        this.bank
      ).then(res => {
        if (res.status === 201) {
          this.$emit("newBankSaved");
        }
      });
    }
  },
  validations: {
    bank: {
      iban_code: {
        required,
        ibanValidator
      }
    }
  }
};
</script>

<style scoped></style>
